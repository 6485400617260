import React, { useState } from 'react';
// import slugify from 'slugify';
// import { getTreeFromFlatData } from 'react-sortable-tree';
// import JoditEditor from 'jodit-react';
import {
  // Tabs,
  Divider,
  Input,
  // Checkbox,
  Select /* DatePicker, */,
  // Button,
  Form,
  Row,
  Col,
  // TreeSelect,
} from 'antd';
// import dayjs from 'dayjs';
// import moment from 'moment';
// import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import { GalleryModal } from './';

// const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 30;

// const configJodit = {
//   readonly: false, // all options from https://xdsoft.net/jodit/doc/
//   toolbarAdaptive: false,
//   minHeight: '300',
//   buttons: [
//     'source',
//     '|',
//     'bold',
//     'strikethrough',
//     'underline',
//     'italic',
//     'eraser',
//     '|',
//     'superscript',
//     'subscript',
//     '|',
//     'ul',
//     'ol',
//     '|',
//     'outdent',
//     'indent',
//     '|',
//     // 'font',
//     'fontsize',
//     '\n',
//     'brush',
//     'paragraph',
//     'link',
//     'align',
//     // '|',
//     'undo',
//     'redo',
//     'selectall',
//     'cut',
//     'copy',
//     'paste',
//     'copyformat',
//     '|',
//     'hr',
//     'symbol',
//     'fullsize',
//     'print',
//     'preview',
//     'find',
//   ],
// };

const formInit = {
  _id: null,
  title: '',
  status: 'ACTIVE',
  gallery: [],
};

const EditGalleryFrom = ({ isNew, data, categories, onSubmit, SERVER_URL, token, declarations }) => {
  const [form] = Form.useForm();

  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };
  // initialValues.url = data && data.url?.sr;

  const [currentGallery, setCurrentGallery] = useState(initialValues.gallery?.length > 0 ? initialValues.gallery : []);

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={10}>
              <div className='panel-body'>
                <Form.Item
                  label='Naziv galerije'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas da unesete naziv galerije!',
                    },
                  ]}
                  name='title'
                  // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                >
                  <Input />
                </Form.Item>

                <Divider type='horizontal' />

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={12}>
                    <div>
                      <Form.Item
                        label='Status'
                        name='status'
                        rules={[{ required: true, message: 'Please select status!' }]}
                      >
                        <Select>
                          {['ACTIVE', 'SUSPENDED'].map((option, index) => (
                            <Select.Option key={`${option}_${index}`} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                {/* <Form.Item label='Na zalihama' name='inStock' valuePropName='checked'>
                  <Checkbox />
                </Form.Item> */}
              </div>
            </Col>

            <Col xs={24} md={14}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Galerija slika</h4>
                </div>

                <Form.Item name='gallery' valuePropName='image'>
                  <div className='panel-body'>
                    <br />
                    {currentGallery?.length > 0 && (
                      <DNDGallery
                        deleteGalleryImageHandler={deleteGalleryImageHandler}
                        editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                        form={form}
                        setGallery={setCurrentGallery}
                      />
                    )}

                    {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <button className='b2b-primary'>{isNew ? 'Dodaj' : 'Izmeni'} galeriju</button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/gallery/'
            imageType='data'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default EditGalleryFrom;
