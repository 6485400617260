import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Input, Form, Row, Col, Divider, DatePicker, Select, Button } from 'antd';
import moment from 'moment'

const ViewImport = (props) => {
  const currentuser = useContext(UserContext);
  const [items, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const dataId = props.match.params.id;
  const [form] = Form.useForm();
  let initialValues = {
    title: '',
    totalPriceEuroWithoutTax: 0,
    totalPriceRSDWithoutTax: 0
  };

  useEffect(() => {
    if (dataId) fetchData(`${SERVER_URL}/import/${dataId}`, {});
  }, [fetchData, dataId]);

  const isDataFetched = !items?.isLoading && items && items?.data;
  if (isDataFetched) {
    form.setFieldsValue({
      title: items.data.title,
      totalPriceEuroWithoutTax: items.data.totalPriceEuroWithoutTax,
      totalPriceRSDWithoutTax: items.data.totalPriceRSDWithoutTax,
      products: items.data.products,
      totalQuantity: items.data.totalQuantity,
      importDate: moment(items.data.importDate)
    })
  }



  return (
    <div className='panel panel-body'>
      <div className='actions-block'>
        <Link to='/admin/imports'>
          <Button type='primary'>Svi ulazi</Button>
        </Link>
      </div>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          // onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                      <Form.Item
                        label='Naziv uvoza'
                        rules={[
                          {
                            required: true,
                            message: 'Molimo Vas da unesete naziv uvoza!',
                          },
                        ]}
                        name='title'
                        
                      >
                        <Input disabled />
                      </Form.Item>

                      <Form.Item label='Datum ulaza' name='importDate'>
                        <DatePicker format={'DD-MM-YYYY'} disabled/>
                      </Form.Item>

                <Divider type='horizontal' />

                <Row type='flex' gutter={8} >
                  <Col xs={24} md={7}>
                    <Form.Item
                      label={<strong>Ukupna cena (€)</strong>}
                      name='totalPriceEuroWithoutTax'
                      initialValue={0}
                      
                    >
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={7}>
                    <Form.Item
                      label={<strong>Ukupna cena (RSD)</strong>}
                      name='totalPriceRSDWithoutTax'
                      initialValue={0}
                    >
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={7}>
                    <Form.Item
                      label={<strong>Ukupna Količina</strong>}
                      name='totalQuantity'
                      initialValue={0}
                    >
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>
                </Row>

                

                <Divider type='horizontal' />
              </div>
            </Col>

          </Row>

          <Form.List name='products'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', gap: '15px' }}>
                        <Form.Item
                          label='Naziv proizvoda'
                          name={[field.name, 'productId']}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete naziv proizvoda!',
                            },
                          ]}
                          
                        >
                          <Select
                            showSearch
                            placeholder='Odaberite proizvod'
                            optionFilterProp='children'
                            disabled
                          >
                            {items?.data?.items?.map((val) => (
                              <Select.Option value={val.title.sr} key={val._id}>
                              {val.title.sr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label='Količina'
                          name={[field.name, 'quantity']}
                          // onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete količinu!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite količinu' disabled/>
                        </Form.Item>
                        <Form.Item
                          label='Ul. cena bez PDV-a (€)'
                          name={[field.name, 'productPriceWithoutTaxEuro']}
                          // onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete ulazu cenu bez PDV-a u (€)!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite ulazu cenu bez PDV-a u (€)' disabled/>
                        </Form.Item>
                        <Form.Item
                          label='Ul. cena bez PDV-a (RSD)'
                          name={[field.name, 'productPriceWithoutTaxRSD']}
                          initialValue={0}
                          // onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete ulazu cenu bez PDV-a u (RSD)!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite ulazu cenu bez PDV-a u (RSD)' disabled/>
                        </Form.Item>
                        <Form.Item
                          label={<strong>Uk. ul. cena bez PDV-a (€)</strong>}
                          name={[field.name, 'totalProductPriceWithoutTaxEuro']}
                          initialValue={0}
                        >
                          <Input placeholder='' disabled />
                        </Form.Item>
                        <Form.Item
                          label={<strong>Uk. ul. cena bez PDV-a (RSD)</strong>}
                          name={[field.name, 'totalProductPriceWithoutTaxRSD']}
                          initialValue={0}
                        >
                          <Input placeholder='' disabled />
                        </Form.Item>
                      
                      </div>
                      <Divider type='horizontal' />
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};

export default ViewImport;
