import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { notification, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';
import EditUserModal from '../../components/modals/EditModal';

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser?.data?.token, 'get');
  const [showModal, setShowModal] = useState();
  const [search, setSearch] = useState();
  const [useri, setUseri] = useState();
  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = showModal ? 'hidden' : 'auto';
  }, [showModal]);
  //Moze da se pretrazuje po imenu, prezimenu, role, status, grad,email
  //Nalazi se odredjivanje toga u user.controllers.js u searchUsers
  useEffect(() => {
    if (search) {
      Axios.get(`${SERVER_URL}/users/search/${search}`)
        .then((res) => {
          setUseri(res.data.users);
        })
        .catch((err) => console.log(err));
    } else {
      setUseri([]);
    }
  }, [search, currentuser]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je izbrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const columnKeys = ['firstName', 'lastName', 'role', 'status', 'email'];

  let tableData = [];

  if (search) {
    if (useri && useri.length > 0) {
      tableData = useri.map((item) => {
        item.branchName = item.branch?.map((branch, i) => `${branch.branchName}${i < item.branch?.length - 1 ? `,` : ""} `);
        if (Array.isArray(item.role)) item.role = item.role.join(', ');
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      });
    }
  } else if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      item.branchName = item.branch?.map((branch, i) => `${branch.branchName}${i < item.branch?.length - 1 ? `,` : ""} `);
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div className='table'>
      <div

        style={{ justifyContent: 'space-between' }}
        className='actions-block'>
        <Input
          style={{ width: '50%' }}
          bordered={false}
          placeholder='Pretraga'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/*<Link to='/admin/new-user'>*/}
        <button className='b2b-primary' onClick={() => setShowModal(!showModal)}>
          Dodaj korisnika
        </button>

        {/*</Link>*/}
      </div>

      <div style={{ textAlign: 'center' }}>
        {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={columnKeys}
            title='User'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
      <EditUserModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Users;
