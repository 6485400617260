import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import InvoiceForm from '../../components/forms/InvoiceForm';

const EditInvoicesPage = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const { factureId } = props.match.params;
  
  const [invoice, fetchInvoice] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    if (factureId) fetchInvoice(`${SERVER_URL}/invoices/${factureId}`, {});
  }, [fetchInvoice, factureId]);

  const onSubmit = async (formData) => {
    const method = 'put';
    const route = `${SERVER_URL}/edit-invoice/${factureId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then(res => {
        console.log(res)
      })
      notification.success({
        message: `Faktura je izmenjena.`,
        placement: 'bottomRight',
      });
      history.push('/admin/invoices');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async (orderData) => {
    const method = 'post';
    const route = `${SERVER_URL}/invoices`;
    try {
      await Axios[method](
        route,
        {
          orderId: factureId,
          email: invoice.data?.company?.email,
          invoice: orderData,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Račun je kreiran.`,
        placement: 'bottomRight',
      });
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(error.response);
      notification.error({
        message: `${msg}`,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !invoice.isLoading && Object.keys(invoice.data).length > 0 && currentuser.language;
  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/invoices'>
          <button className='b2b-primary'>Sve fakture</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {factureId && !invoice.isError && invoice.data && isDataFetched && (
          <InvoiceForm
            data={invoice.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
            createInvoice={createInvoice}
            factureId={factureId}
          />
        )}
      </div>
    </div>
  );
};

export default EditInvoicesPage;
