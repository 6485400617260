import React, { useState } from 'react';
import { Input, Select, Form, Row, Col, DatePicker } from 'antd';
import moment from 'moment';

const { Option } = Select;

const formInit = {
  obligationText: undefined,
  // obligationDate: undefined,
  status: undefined,
  companyName: undefined,
};

const ObligationForm = ({ isNew, data, onSubmit, items }) => {
  const [form] = Form.useForm();

  const [obligationDate, setObligationDate] = useState(data && moment(data?.obligationDate));

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  // if (data) {
  //   data.obligationDate = data.obligationDate && moment(data.obligationDate);
  // }

  const handleChangeEndDate = (e) => {
    console.log('EEEEEE', e);
    setObligationDate(e);
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }

    values.obligationDate = obligationDate;

    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Form.Item
                  label='Naziv obligacije'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas da unesete naziv obligacije!',
                    },
                  ]}
                  name='obligationText'
                  // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                >
                  <Input
                    onChange={(event) =>
                      form.setFieldsValue({ url: event.target.value.replace(/ /g, '-').toLowerCase() })
                    }
                  />
                </Form.Item>

                <Form.Item label='Datum obligacije'>
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    value={obligationDate}
                    onChange={(e) => {
                      handleChangeEndDate(e);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label='Naziv klijenta'
                  name={`companyName`}
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete izaberete naziv klijenta!',
                    },
                  ]}
                >
                  <Select showSearch placeholder='Odaberite klijenta' optionFilterProp='children'>
                    {items?.data?.items?.map((val) => (
                      <Select.Option value={val.companyName} key={val._id}>
                        {val.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  label='Status obligacije'
                  name='status'
                >
                  <Select>
                    <Option value='OTVORENA'>OTVORENA</Option>
                    <Option value='ZATVORENA'>ZATVORENA</Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className='text-right'>
            <button className='b2b-primary'>{isNew ? 'Dodaj' : 'Izmeni'} obligaciju</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ObligationForm;

// const ObligationForm = ({ isNew, data, onSubmit, language, token }) => {
//   const currentuser = useContext(UserContext);

//   const [form] = Form.useForm();
//   const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');

//   let initialValues = { ...formInit, ...data };
//   const [obligationDate, setObligationDate] = useState(moment(initialValues.obligationDate));

//   useEffect(() => {
//     if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
//       fetchCompanies(`${SERVER_URL}/companies`, []);
//     } else {
//       let filter = { client: currentuser.data.id };
//       if (companies.data.length === 0) {
//         fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
//       }
//       if (companies && companies.data && companies.data.items && companies.data.items.length === 1) {
//         filter = { $or: [{ _id: companies.data.items[0]._id }, { mainCompany: companies.data.items[0]._id }] };
//         fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
//       }
//     }
//   }, [fetchCompanies, currentuser.data.id, currentuser.data.role, companies]);

//   const onFinish = async (values, isNew) => {
//     values.obligationDate = obligationDate;
//     onSubmit(values, isNew);
//   };

//   return (
//     <div className='dashboard'>
//       <div className='card-wrapper'>
//         <Card title='Izmena obligacije' bordered={false}>
//           <Form
//             className='form-horizontal'
//             initialValues={initialValues}
//             onFinish={(values) => onFinish(values, !data)}
//             layout='vertical'
//             form={form}
//           >
//             <Form.Item
//               style={{
//                 display: 'inline-block',
//                 width: '100%',
//               }}
//               label='Obligacija'
//               name='obligationText'
//             >
//               <Input placeholder='Naziv obligacije' />
//             </Form.Item>

//             <Form.Item
//               style={{
//                 display: 'inline-block',
//                 width: '100%',
//               }}
//               label='Status obligacije'
//               name='status'
//             >
//               <Select>
//                 <Option value='OTVORENA'>OTVORENA</Option>
//                 <Option value='ZATVORENA'>ZATVORENA</Option>
//               </Select>
//             </Form.Item>

//             <Form.Item label='Datum obligacije' name='obligationDate'>
//               <DatePicker format={'DD-MM-YYYY'}/>
//             </Form.Item>

//             <div className='text-right'>
//               <Button type='primary' htmlType='submit'>
//                 Izmeni obligaciju
//               </Button>
//             </div>
//           </Form>
//         </Card>
//       </div>
//     </div>
//   );
// };

// export default ObligationForm;
