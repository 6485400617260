import React, { useEffect, useContext, useState } from 'react';
import { Divider, Input, Form, Card, Select, Row, Col, notification, DatePicker } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';
import { Link } from 'react-router-dom';
import Axios from 'axios';
// import locale from 'antd/es/date-picker/locale/sr_RS';

const initialValues = {
  companyName: undefined,
  dateOfPayment: undefined,
  productName: undefined,
  quantity: undefined,
  discountPercentage: undefined,
};
const NewProfacture = (companyName) => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [counter, setCounter] = useState(1);
  const [buttonClick, setButtonClick] = useState('');
  const [form] = Form.useForm();
  const [orderId, setOrderId] = useState('')
  // const history = useHistory();

  initialValues.companyName = companyName ? companyName?.companyName : undefined;

  const onSubmit = async (formData) => {
    const method = 'post';
    const route = `${SERVER_URL}/${buttonClick === 'profacture' ? 'orders' : 'invoiceDirect'}`;

    const payload = buttonClick === 'profacture' ? formData : {
      ...formData,
      checkOrderId: orderId
    }

    try {
      const res = await Axios[method](route, payload, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: buttonClick === 'invoice' ? 'Faktura je kreirana.' : 'Profaktura je kreirana.',
        placement: 'bottomRight',
      });

      if(res.status === 200){
        setOrderId(res.data._id)
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else {
      let filter = { client: currentuser.data.id };
      if (companies.data.length === 0) {
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
      if (companies && companies.data && companies.data.items && companies.data.items.length === 1) {
        filter = { $or: [{ _id: companies.data.items[0]._id }, { mainCompany: companies.data.items[0]._id }] };
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
    }
  }, [fetchCompanies, currentuser.data.id, currentuser.data.role, companies]);

  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, []);
  }, [fetchData]);

  const onFinish = async (values) => {
    let productNames = Object.keys(values).filter((key) => key.includes('productName'));

    const products = [];
    let totPrice = 0;

    productNames.forEach((prod, index) => {
      let prodValue = values[prod];

      const findProd = data.data.items.find((p) => p._id === prodValue);

      let quantity = values['quantity' + index];

      let discountPercentage = values['discountPercentage' + index];

      const toAdd = {
        _id: findProd._id,
        category: findProd?.category?.name?.sr,
        code: findProd.code,
        name: findProd.title?.sr,
        quantity,
        discountPercentage,
        price: findProd?.wholesalePrice,
      };
      products.push(toAdd);
      totPrice = totPrice + toAdd.price;
    });

    onSubmit({
      // ...data,
      dateOfPayment: values.dateOfPayment._d.toLocaleDateString('en-GB'),
      dateOfSupply: values.dateOfSupply._d.toLocaleDateString('en-GB'),
      dateOfDocument: values.dateOfDocument._d.toLocaleDateString('en-GB'),
      companyName: values.companyName,
      order: products,
      totalPrice: totPrice.toFixed(2),
      totalPriceWithTax: (totPrice + totPrice * 0.2).toFixed(2),
      status: form.getFieldValue('status'),
    });
  };

  const filteredNonVisible = data?.data?.items?.filter(item => item.visible !== true)

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/profactures'>
          <button className='b2b-primary'>Sve profakture</button>
        </Link>
      </div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='Nova profaktura' bordered={false}>
            <Form
              className='form-horizontal'
              initialValues={initialValues}
              onFinish={(values) => onFinish(values, !data)}
              layout='vertical'
              form={form}
            >
              <Form.Item
                label='Naziv klijenta'
                name='companyName'
                rules={[
                  {
                    required: true,
                    message: 'Molim Vas da unesete naziv klijenta!',
                  },
                ]}
              >
                <Select showSearch placeholder='Odaberite klijenta' optionFilterProp='children'>
                  {companies?.data?.items?.map((val) => (
                    <Select.Option value={val._id} key={val._id}>
                      {val.companyName}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Input placeholder='Naziv klijenta' /> */}
              </Form.Item>

              <div style={{ display: 'flex', flexDirection: 'horizontal', justifyContent: 'space-between' }}>
                <Form.Item
                  label='Datum izdavanja dokumenta'
                  name='dateOfDocument'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete datum izdavanja dokumenta!',
                    },
                  ]}
                >
                  <DatePicker placeholder='Odaberite datum' format='DD/MM/YYYY' />
                </Form.Item>

                <Form.Item
                  label='Datum plaćanja'
                  placeholder='Odaberite datum'
                  name='dateOfPayment'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete datum plaćanja!',
                    },
                  ]}
                >
                  <DatePicker placeholder='Odaberite datum' format='DD/MM/YYYY' />
                </Form.Item>

                <Form.Item
                  label='Datum isporuke'
                  name='dateOfSupply'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas da unesete datum isporuke!',
                    },
                  ]}
                >
                  <DatePicker placeholder='Odaberite datum' format='DD/MM/YYYY' />
                </Form.Item>
              </div>

              {(() => {
                const rows = [];
                for (let i = 0; i < counter; i++) {
                  rows.push(
                    <Row gutter={14} key={1}>
                      <Col xs={24} md={8}>
                        <Form.Item
                          label='Naziv proizvoda'
                          name={`productName${i}`}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete naziv proizvoda!',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder='Odaberite proizvod'
                            optionFilterProp='children'
                            // onChange={(value) => {
                            //   form.setFieldsValue({ attributes: [] });
                            //   const categoryPath = getCategoryPath(value, categories);
                            //   fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
                            // }}
                          >
                            {filteredNonVisible && filteredNonVisible.length > 0 && filteredNonVisible.map((val) => (
                              <Select.Option value={val._id} key={val._id}>
                                {val.title.sr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item
                          label='Količina'
                          name={`quantity${i}`}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete količinu!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite količinu' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={5}>
                        <Form.Item
                          label='Rabat'
                          name={`discountPercentage${i}`}
                          rules={[
                            {
                              required: false,
                              message: 'Molim Vas da unesete rabat!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite rabat' />
                        </Form.Item>
                      </Col>
                    </Row>,
                  );
                }
                return rows;
              })()}

              {/* for (let i = 0; i < counter; i++)  {
            
            <Row gutter={14}>
            <Col xs={24} md={8}>
            <Form.Item label='Naziv proizvoda' name='productName'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete naziv proizvoda!',
                },
              ]}>
              <Select
                    showSearch
                    placeholder="Odaberite proizvod"
                    optionFilterProp="children"
                    // onChange={(value) => {
                    //   form.setFieldsValue({ attributes: [] });
                    //   const categoryPath = getCategoryPath(value, categories);
                    //   fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
                    // }}
                  >
                    {data?.data?.items?.map((val) => (
                        <Select.Option value={val._id} key={val._id}>
                            {val.title.sr}
                        </Select.Option>
                    ))}
                  </Select>
            </Form.Item>
            </Col>
            <Col xs={24} md={5}>
              <Form.Item label='Količina' name='quantity'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete količinu!',
                },
              ]}>
              <Input placeholder='Unesite količinu' />
            </Form.Item>
            </Col>
            </Row>
         
            } */}

              <Row>
                <Col xs={24}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <button className='b2b-primary' onClick={() => setCounter(counter + 1)}>
                      Dodatni proizvod
                    </button> */}
                    <div className='b2b-primary kontakt-button' onClick={() => setCounter(counter + 1)}>
                      Dodatni proizvod
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider type='horizontal' />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className='text-right'>
                  <button
                    className='b2b-primary submit'
                    style={{ marginRight: '10px' }}
                    onClick={() => setButtonClick('invoice')}
                  >
                    Kreiraj fakturu
                  </button>
                </div>
                <div className='text-right'>
                  <button className='b2b-primary submit' onClick={() => setButtonClick('profacture')}>
                    Dodaj profakturu
                  </button>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default NewProfacture;
