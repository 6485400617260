import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUserModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const userEdit = props.userEdit;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (userEdit) {
      fetchUser(`${SERVER_URL}/users/${userEdit._id}`, []);
    }
  }, [userEdit, fetchUser]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${userEdit._id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Korisnik je izmenjen.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom korisnika. Molim Vas pokušajte opet.',
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      let isError = false;
      if (data.firstName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeće ime!',
          placement: 'bottomRight',
        });
      }
      if (data.lastName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeće prezime!',
          placement: 'bottomRight',
        });
      }
      if (data.password.includes(' ')) {
        isError = true;
        notification.error({
          message: 'Nevažeća lozinka!',
          placement: 'bottomRight',
        });
      }
      if (data.taxId && data.taxId.trim() === '') {
        isError = true;
        notification.error({
          message: 'Nevažeći PIB!',
          placement: 'bottomRight',
        });
      }
      if (isError === false) {
        await Axios.post(`${SERVER_URL}/register`, { ...data });
        notification.success({
          message: 'Korisnik je napravljen.',
          placement: 'bottomRight',
        });
        setResult(true);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa kreiranjem korisnika. Molim Vas pokušajte opet.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <div className={showModal ? 'overlay show' : 'overlay'} onClick={() => setShowModal(!showModal)} />
      <div className={showModal ? 'modal show' : 'modal'}>
        {/* <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary'>All users</Button>
        </Link>
      </div> */}

        <div style={{ textAlign: 'center', height: '100%' }}>
          {userEdit && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {userEdit && !user.isLoading && !user.isError && user.data && user.data.email && (
            <UserForm
              data={user.data}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          )}
          {userEdit && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
          {!userEdit && user && !user.data && (
            <UserForm
              data={null}
              createHandler={createUser}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditUserModal;
