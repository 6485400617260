import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../App';
import { Divider, Input, Select, Form, Row, Col, DatePicker, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
// import dayjs from 'dayjs';
import moment from 'moment';

const formInit = {
  _id: null,
  title: '',
  // importDate: undefined,
  products: [],
};

const ImportForm = ({ isNew, data, onSubmit, SERVER_URL }) => {
  const [form] = Form.useForm();

  const [importDate, setImportDate] = useState(data && moment(data?.importDate));
  const currentuser = useContext(UserContext);
  const [items, fetchProducts] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchProducts(`${SERVER_URL}/data`, []);
  }, [fetchProducts, SERVER_URL]);

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    values.importDate = importDate;

    onSubmit(values, isNew);
  };

  const handleTotalPriceByProductsInEuro = function (i) {
    const quantity = Number(form.getFieldValue(['products', i, 'quantity'])) || 0;
    const unitPriceEuro = Number(form.getFieldValue(['products', i, 'productPriceWithoutTaxEuro'])) || 0;
    const unitPriceRSD = Number(form.getFieldValue(['products', i, 'productPriceWithoutTaxRSD'])) || 0;

    const totalPriceEuro = quantity * unitPriceEuro;
    const totalPriceRSD = quantity * unitPriceRSD;

    console.log('results of calc', totalPriceEuro);
    if (totalPriceEuro > 0) {
      const updatedValues = form.getFieldsValue(['products']);
      console.log('UPDATED FIELDS', updatedValues);
      updatedValues.products[i].totalProductPriceWithoutTaxEuro = totalPriceEuro;
      updatedValues.products[i].totalProductPriceWithoutTaxRSD = totalPriceRSD;

      form.setFieldsValue({ products: updatedValues });
      calculateTotalPriceEuro();
      calculateTotalPriceRsd();
    }
  };

  const calculateTotalPriceEuro = () => {
    const productFields = form.getFieldValue('products') || [];
    let totalEuro = 0;

    productFields.map((product) => {
      const totalPriceEuro = product.totalProductPriceWithoutTaxEuro || 0;
      totalEuro += totalPriceEuro;

      return totalEuro;
    });

    form.setFieldsValue({ totalPriceEuroWithoutTax: totalEuro });
  };

  const handleChangeEndDate = (e) => {
    console.log('EEEEEE', e);
    setImportDate(e);
  };

  const calculateTotalPriceRsd = () => {
    const productFields = form.getFieldValue('products') || [];
    let totalRSD = 0;

    let totalQuantity = 0;

    console.log('PRODUCT FIELDS', productFields);

    productFields.map((product) => {
      const totalPriceRSD = product.totalProductPriceWithoutTaxRSD || 0;

      totalRSD += totalPriceRSD;
      totalQuantity += Number(product.quantity);
      return totalRSD;
    });

    form.setFieldsValue({ totalPriceRSDWithoutTax: totalRSD });

    form.setFieldsValue({ totalQuantity: totalQuantity });
  };

  const filteredNonVisible = items?.data?.items?.filter(item => item.visible !== true)

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Form.Item
                  label='Naziv ulaza'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas da unesete naziv ulaza!',
                    },
                  ]}
                  name='title'
                  // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                >
                  <Input
                    onChange={(event) =>
                      form.setFieldsValue({ url: event.target.value.replace(/ /g, '-').toLowerCase() })
                    }
                  />
                </Form.Item>

                <Form.Item label='Datum ulaza'>
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    value={importDate}
                    onChange={(e) => {
                      handleChangeEndDate(e);
                    }}
                  />
                </Form.Item>

                <Divider type='horizontal' />

                <Row type='flex' gutter={8}>
                  <Col xs={24} md={7}>
                    <Form.Item
                      label={<strong>Ukupna cena (€)</strong>}
                      name={`totalPriceEuroWithoutTax`}
                      initialValue={0}
                    >
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={7}>
                    <Form.Item
                      label={<strong>Ukupna cena (RSD)</strong>}
                      name={`totalPriceRSDWithoutTax`}
                      initialValue={0}
                    >
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={7}>
                    <Form.Item label={<strong>Ukupna količina</strong>} name={`totalQuantity`} initialValue={0}>
                      <Input placeholder='' disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider type='horizontal' />
              </div>
            </Col>
          </Row>

          <Form.List name='products'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', gap: '15px' }}>
                        <Form.Item
                          label='Naziv proizvoda'
                          name={[field.name, 'productId']}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete naziv proizvoda!',
                            },
                          ]}
                        >
                          <Select showSearch placeholder='Odaberite proizvod' optionFilterProp='children'>
                            {filteredNonVisible && filteredNonVisible.length > 0 && filteredNonVisible.map((val) => (
                              <Select.Option value={val.title.sr} key={val._id}>
                                {val.title.sr}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label='Količina'
                          name={[field.name, 'quantity']}
                          onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete količinu!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite količinu' />
                        </Form.Item>
                        <Form.Item
                          label='Ul. cena bez PDV-a (€)'
                          name={[field.name, 'productPriceWithoutTaxEuro']}
                          onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete ulazu cenu bez PDV-a u (€)!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite ulazu cenu bez PDV-a u (€)' />
                        </Form.Item>
                        <Form.Item
                          label='Ul. cena bez PDV-a (RSD)'
                          name={[field.name, 'productPriceWithoutTaxRSD']}
                          initialValue={0}
                          onChange={() => handleTotalPriceByProductsInEuro(index)}
                          rules={[
                            {
                              required: true,
                              message: 'Molim Vas da unesete ulazu cenu bez PDV-a u (RSD)!',
                            },
                          ]}
                        >
                          <Input placeholder='Unesite ulazu cenu bez PDV-a u (RSD)' />
                        </Form.Item>
                        <Form.Item
                          label={<strong>Uk. ul. cena bez PDV-a (€)</strong>}
                          name={[field.name, 'totalProductPriceWithoutTaxEuro']}
                          initialValue={0}
                        >
                          <Input placeholder='' disabled />
                        </Form.Item>
                        <Form.Item
                          label={<strong>Uk. ul. cena bez PDV-a (RSD)</strong>}
                          name={[field.name, 'totalProductPriceWithoutTaxRSD']}
                          initialValue={0}
                        >
                          <Input placeholder='' disabled />
                        </Form.Item>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // marginBottom: '5px',
                            fontWeight: '600',
                          }}
                        >
                          <Button
                            type='text'
                            style={{
                              // border: 'none',
                              // background: 'transparent',
                              // boxShadow: 'none',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              remove(field.name);
                              calculateTotalPriceEuro();
                              calculateTotalPriceRsd();
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                        </div>
                      </div>
                      <Divider type='horizontal' />
                    </div>
                  </div>
                ))}

                <div
                  className='add-contact-button'
                  style={{ display: 'flex', justifyContent: 'start', marginBottom: '40px' }}
                >
                  <div
                    className='b2b-primary kontakt-button'
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => add()}
                  >
                    <span>Dodaj novi proizvod</span>
                  </div>
                </div>
              </>
            )}
          </Form.List>

          <div className='text-right'>
            <button className='b2b-primary'>{isNew ? 'Dodaj' : 'Izmeni'} uvoz</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ImportForm;
