import React, { useState } from 'react';
import { Divider, Input, Form, Card, Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;


const formInit = {
  companyName: undefined,
  address: undefined,
  city: undefined,
  postalCode: undefined,
  country: undefined,
  phone: undefined,
  taxId: undefined,
  matBr: undefined,
  email: undefined,
};

const CompanyForm = ({ isNew, data, createCompany, updateCompany }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const [counter, setCounter] = useState(isNew ? 1 : initialValues?.contactPerson?.length);

  const onFinish = async (values, isNew) => {

    const contacts = [];
    for (let i = 0; i < counter; i++) {
      contacts.push({
        contactPerson: values[`licezakontakt${i}`],
        contactPhone: values[`licezakontakttelefon${i}`],
        contactEmail: values[`licezakontaktemail${i}`],
        contactPosition: values[`licezakontaktpozicija${i}`],
      });
    }

    values.contactPerson = contacts;

    if (isNew) {
      createCompany(values);
    } else {
      updateCompany(values);
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={isNew ? 'Dodaj klijenta' : 'Izmeni klijenta'} bordered={false}>
          <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
            <Form.Item label='Naziv klijenta' name='companyName'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete naziv klijenta!',
                },
              ]}>

              <Input placeholder='Naziv klijenta' />
            </Form.Item>

            <Form.Item label='Matični broj' name='matBr'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete matični broj!',
                },
              ]}>

              <Input placeholder='Matični broj klijenta' />
            </Form.Item>


            <Form.Item label='Telefon' name='phone'
              rules={[
                {
                  required: false,
                  message: 'Molim Vas da unesete broj telefona!',
                },
              ]}>
              <Input placeholder='Broj telefona' />
            </Form.Item>

            <Form.Item label='Email' name='email'>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item label='Poštanski broj' name='postalCode'
              rules={[
                {
                  required: false,
                  message: 'Molimo Vas da unesete poštanski broj!',
                },
              ]}>
              <Input placeholder='Poštanski broj klijenta' />
            </Form.Item>

            {/*<Form.Item label='Zemlja' name='drzava'>*/}
            {/*  <Input disabled={true} placeholder='Zemlja' />*/}
            {/*</Form.Item>*/}

            <Form.Item label='Grad' name='city'>
              <Input placeholder='Grad' />
            </Form.Item>

            <Form.Item label='Adresa' name='address'
              rules={[
                {
                  required: true,
                  message: 'Molim Vas da unesete adresu!',
                },
              ]}>
              <Input placeholder='Adresa klijenta' />
            </Form.Item>

            <Form.Item
              label='PIB'
              name='taxId'
              rules={[
                {
                  required: true,
                  message: 'Molimo Vas da unesete PIB!',
                },
              ]}
            >
              <Input placeholder='Poreski broj klijenta' />
            </Form.Item>

            <Form.Item
              label='Tekući račun'
              name='tekuciracun'
            >
              <Input placeholder='Tekući račun' />
            </Form.Item>

            {/*<Form.Item label='Fax' name='fax'>*/}
            {/*  <Input placeholder='Broj faxa' />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label='Rabat kupcu' name='rabatkupcu'>*/}
            {/*  <Input placeholder='Rabat kupcu' />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label='Rabat dobavljaču' name='rabatdobavljacu'>*/}
            {/*  <Input placeholder='Rabat dobavljaču' />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label='Rok plaćanja kupcu' name='rokplacanjakupcu'>*/}
            {/*  <Input placeholder='Rok plaćanja kupcu' />*/}
            {/*</Form.Item>*/}

            {isNew && (() => {
              const rows = [];
              for (let i = 0; i < counter; i++) {
                rows.push(

                  <div className='kontakt-wrapper' style={{ marginBottom: 0 }}>
                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                      }}
                      label='Kontakt osoba'
                      name={`licezakontakt${i}`}
                    >
                      <Input placeholder='Ime kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 0 0 8px',
                      }}
                      label='Telefon kontakt osobe'
                      name={`licezakontakttelefon${i}`}
                    >
                      <Input placeholder='Broj telefona kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 0 0 8px',
                      }}
                      label='Email kontakt osobe'
                      name={`licezakontaktemail${i}`}
                    >
                      <Input placeholder='Email adresa kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25%)',
                        margin: '0 0 0 8px',
                      }}
                      label='Pozicija kontakt osobe'
                      name={`licezakontaktpozicija${i}`}
                    >
                      <Input placeholder='Pozicija kontakt osobe u kompaniji' />
                    </Form.Item>
                  </div>
                );
              }
              return rows;
            })()}

            {!isNew && (() => {
              const rows = [];
              // const counter = initialValues.contactPerson.length;
              for (let i = 0; i < counter; i++) {
                if (i < initialValues.contactPerson.length) {
                  form.setFieldsValue({
                    [`licezakontakt${i}`]: initialValues.contactPerson[i].contactPerson,
                    [`licezakontakttelefon${i}`]: initialValues.contactPerson[i].contactPhone,
                    [`licezakontaktemail${i}`]: initialValues.contactPerson[i].contactEmail,
                    [`licezakontaktpozicija${i}`]: initialValues.contactPerson[i].contactPosition,
                  });
                }
                rows.push(

                  <div className='kontakt-wrapper' style={{ marginBottom: 0 }}>
                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                      }}
                      label='Kontakt osoba'
                      name={`licezakontakt${i}`}
                    >
                      <Input placeholder='Ime kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 0 0 8px',
                      }}
                      label='Telefon kontakt osobe'
                      name={`licezakontakttelefon${i}`}
                    >
                      <Input placeholder='Broj telefona kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 0 0 8px',
                      }}
                      label='Email kontakt osobe'
                      name={`licezakontaktemail${i}`}
                    >
                      <Input placeholder='Email adresa kontakt osobe' />
                    </Form.Item>

                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(25%)',
                        margin: '0 0 0 8px',
                      }}
                      label='Pozicija kontakt osobe'
                      name={`licezakontaktpozicija${i}`}
                    >
                      <Input placeholder='Pozicija kontakt osobe u kompaniji' />
                    </Form.Item>
                  </div>
                );
              }
              return rows;
            })()}

            <div className='add-contact-button'>
              <div className='b2b-primary kontakt-button' onClick={() => setCounter(counter + 1)}>
                Dodaj kontakt osobu
              </div>
            </div>

            <div className='task-wrapper' style={{ marginBottom: 0 }}>
              <Form.Item
                style={{
                  display: 'inline-block',
                  width: 'calc(40% - 8px)',
                }}
                label='Task'
                name='taskName'
              >
                <Input placeholder='Naziv taska' />
              </Form.Item>

              <Form.Item
                style={{
                  display: 'inline-block',
                  width: 'calc(20% - 8px)',
                  margin: '0 0 0 8px',
                }}
                label='Status taska'
                name='taskStatus'
              >
                <Select>
                  <Option value='U PROCESU'>U PROCESU</Option>
                  <Option value='PRIHVAĆENO'>PRIHVAĆENO</Option>
                  <Option value='ODBIJENO'>ODBIJENO</Option>
                </Select>
              </Form.Item>

              <Form.Item
                style={{
                  display: 'inline-block',
                  width: 'calc(40%)',
                  margin: '0 0 0 8px',
                }}
                label='Opis taska'
                name='taskText'
              >
                <TextArea rows={4} placeholder='Tekstualni opis taska' />
              </Form.Item>
            </div>

            <Divider type='horizontal' />
            <div className='text-right'>
              <button className='b2b-primary submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} klijenta
              </button>
            </div>
          </Form >
        </Card>
      </div >
    </div >
  );
};

export default CompanyForm;
