import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ObligationsTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'googleDescription',
  'keywords',
  'content',
  'image',
  'user',
  'url',
  'tag',
  'text',
  'taskId',
];

const Obligations = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/obligations`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/obligations/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });

      history.push('/admin');
      history.push('/admin/obligation');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data && data.data.length > 0) {
    // const keys = Object.keys(data.data[data.data.length - 1]);
    // columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys = ["obligationText","obligationDate","companyName","status"]
  }

  // function for date formatting in table (dd-mm-yyyy)
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  };

  let tableData = [];
  if (data.data && data.data && data.data.length > 0) {
    tableData = data.data.map((item) => {
      if (item.company && item.company.companyName) {
        item.company = item.company.companyName;
      }
      // item.user = item.createdBy.email;
      const obligationDateFormat = formatDate(item.obligationDate);
      return { ...item, obligationDate: obligationDateFormat };
    });
  }
  console.log(data.data)

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/new-obligation'><Button type='primary'>Nova obligacija</Button></Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Data'
            editPath='/admin/edit-data/'
          />
        )}
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data && data.data.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Data'
            editPath='/admin/edit-obligation/'
          />
        )}
        {!data.isLoading && data.data && data.data && data.data.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Obligations;
