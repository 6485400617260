import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'Naziv',
  'createdBy',
  'modifiedBy',
  'zip',
  'pib',
  'address',
  'calculationMethod',
  'calculationMethodMeal',
  'calculationMethodHoliday',
  'workTypes',
  'travelExpenses',
  'overTime2',
  'otherIncome',
  'suspension',
  'otherExpenses',
  'mealCurrency',
  'holidayCurrency',
  'travelExpCurrency',
  'type',
  'mainCompany',
  'contactPerson',
  'taskName',
  'taskText',
  'taskStatus',
  'isHr',
  'authorizedPerson',
  'attachments',
];

const Company = () => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompanies(`${SERVER_URL}/companies`, []);
    } else {
      let filter = { client: currentuser.data.id };
      if (companies.data.length === 0) {
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
      if (companies && companies.data && companies.data.items && companies.data.items.length === 1) {
        filter = { $or: [{ _id: companies.data.items[0]._id }, { mainCompany: companies.data.items[0]._id }] };
        fetchCompanies(`${SERVER_URL}/companies?filter=${JSON.stringify(filter)}`, []);
      }
    }
  }, [fetchCompanies, currentuser.data.id, currentuser.data.role, companies]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Klijent je izbrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (companies.data.items && companies.data.items.length > 0) {
    const keys = Object.keys(companies.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'name') {
        alternativeNames.push('Name of company');
      } else if (columnKey === 'code') {
        alternativeNames.push('Registration number');
      } else if (columnKey === 'pib') {
        alternativeNames.push('TIN');
      } else if (columnKey === 'city') {
        alternativeNames.push('Address');
      } else if (columnKey === 'client') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('Created on');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('Updated on');
      }
    }
    let keyArray = columnKeys;
    const newKeys = ['postalCode','phone','companyName','matBr','email','city','taxId','createdAt','updatedAt']
    newColumnKeys = newKeys.map((item, index) => ({
      originalName: item,
      // alternativeName: alternativeNames[index],
      alternativeName: keyArray[index],
    }));
  }

  // date formatting for dates in table
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  let tableData = [];
  if (companies.data.items && companies.data.items.length > 0) {
    tableData = companies.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      const createdAtFormat = formatDate(item.createdAt);
      const updatedAtFormat = formatDate(item.updatedAt);
      if (item.client && item.client.firstName && item.client.lastName) {
        item.client = item.client.firstName + ' ' + item.client.lastName;
      }
      return { ...item, createdAt: createdAtFormat, updatedAt: updatedAtFormat };
    });
  }
  return (
    <div className='table'>
      <div>
        {/*(currentuser.data.role.includes('admin') ||
  (companies.data.items && companies.data.items.length > 0 && companies.data.items.some((item) => !item.type || item.type === 'main'))) */}
        {currentuser.data.role.includes('superAdmin') && (
          <div className='actions-block'>
            <Link to='/admin/new-company'>
              <Button type='primary'>Novi klijent</Button>
            </Link>
          </div>
        )}

        <div style={{ textAlign: 'center', overflowX: 'auto' }}>
          {/*{companies.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}*/}
          {!companies.isLoading && companies.data.items && companies.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteUserHandler}
              columnKeys={newColumnKeys}
              //title='Firma'
              editPath='/admin/edit-company/'
              contactsPath='/admin/company-contacts/'
              singleCompanyPath='/admin/single-company/'
              // viewPath='/admin/view-company/'
            />
          )}
          {/*{!companies.isLoading && companies.data.items && companies.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}*/}
        </div>
      </div>
    </div>
  );
};

export default Company;
