import React, { useContext } from 'react';
import { Table, Popconfirm, Input } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import {Link} from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

const InvoicesTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, openPdf }) => {
  // const { t } = useTranslation();
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      // if (dataIndex === 'dateOfPayment' || dataIndex === 'createdAt' || dataIndex === 'updatedAt') {
      //   return (
      //     <div>
      //       <DatePicker />
      //     </div>
      //   );
      // } else

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + `${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 210, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getRowClassName = (record, index) => {
    if (record?.status?.props?.children === 'Storno' || record.status === 'Canceled') {
      return 'invoice_storno_color';
    }
  };

  
  const columns = columnKeys.map((item,index) => {
    let title = ''
    if(index === 0) title = 'Status'
    if(index === 1) title = 'Naziv PDF fajla'
    if(index === 2) title = 'Datum plaćanja'
    if(index === 3) title = 'Ime kupca'
    if(index === 4) title = 'Kreirano'
    if(index === 5) title = 'Izmenjeno'
    
    return {
      key: item,
      title: title,
      dataIndex:
        item === 'createdBy'
          ? 'user'
          : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
      ...getColumnSearchProps(
        item === 'createdBy'
          ? 'user'
          : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
      ),
    }
  });
  // columns.push({
  //   title: 'Kupac',
  //   render: (record) => <span>{record.buyerName}</span>,
  // });
  columns.push({
    title: 'Ukupan iznos',
    render: (record) => <span>{parseFloat(record.totalPrice).toLocaleString('sr-RS')}</span>,
  });

  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <button
            onClick={() => {
              openPdf(record.pdfFileName);
            }}
            className='b2b-primary'
          >
            {/* <EyeOutlined
              // className='icon-unlock'
              title={`Pogledaj ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            /> */}
            {/* <EyeFilled
              className='icon-lock'
              title={`View ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            /> */}
            {/* <span>Pogledaj {title.slice(0, -1).toLowerCase()}</span> */}
            <span>Pregled računa</span>
          </button>
        </div>

        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          {record.orderId ? (
            <Link to={`/admin/edit-invoice/${record._id}`}>
              <EditOutlined
                className='icon-unlock'
                // title={`Edit ${title.toLowerCase()}`}
                title={`Izmeni obligaciju`}
                style={{ textDecoration: 'none', color: 'black' }}
            />
              <EditFilled
                className='icon-lock'
                // title={`Edit ${title.toLowerCase()}`}
                title={`Izmeni obligaciju`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          ) : (
            <div>
              <EditOutlined
                // title={`Edit ${title.toLowerCase()}`}
                className='disabled_edit'
                title={`Izmeni obligaciju`}
                style={{ textDecoration: 'none', color: 'black' }}
            />
            </div>
          )}
        </div>

        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            // title={`This will delete ${title.toLowerCase()}`}
            title={`Ovo će obrisati račun`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <DeleteOutlined
              className='icon-unlock'
              // title={`Delete ${title.toLowerCase()}`}
              title={`Obriši račun`}
            />
            <DeleteFilled
              className='icon-lock'
              // title={`Delete ${title.toLowerCase()}`}
              title={`Obriši račun`}
            />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    // columns[i].title = t(`${columns[i].title}`);
    columns[i].title = `${columns[i].title}`;
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowClassName={(record, index) => getRowClassName(record, index)}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topRight'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default InvoicesTable;
