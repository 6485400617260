import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Modal, Button } from 'antd';
import {
  EditOutlined,
  EditFilled,
  DeleteOutlined,
  SearchOutlined,
  DeleteFilled,
  ContactsOutlined,
  ContactsFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';
// import { useTranslation } from 'react-i18next';

const CompanyTable = ({ data, deleteHandler, columnKeys, editPath, singleCompanyPath, contactsPath }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [deletePop, setDeletePop] = useState();
  const [promptName, setPromptName] = useState();
  const [deleteUser, setDeleteUser] = useState();
  // const { t } = useTranslation();
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za `}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      console.log('dataIndex', dataIndex);
      const filterRecord = Array.isArray(dataIndex.originalName) ? record[dataIndex.originalName[0]][dataIndex.originalName[1]] : record[dataIndex.originalName];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  // const deleteBranch = async (id) => {
  //   try {
  //     const call = await axios.delete(`${SERVER_URL}/branches/${id}`, {
  //       withCredentials: false,
  //       headers: {
  //         Authorization: `Bearer ${user.data.token}`,
  //       },
  //     });
  //     notification.success({
  //       message: call.data.message,
  //       placement: 'bottomRight',
  //     });
  //     setBranches(branches.filter((branch) => branch._id !== id));
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const columns = columnKeys?.map((item, index) => {
    // console.log(item, 'item');
    let title = ''
    if(index === 0) title = 'ZIP'
    if(index === 1) title = 'Telefon'
    if(index === 2) title = 'Kupac'
    if(index === 3) title = 'MB'
    if(index === 4) title = 'Email'
    if(index === 5) title = 'Grad'
    if(index === 6) title = 'PIB'
    if(index === 7) title = 'Kreirano'
    if(index === 8) title = 'Izmenjeno'

    if (item?.originalName === 'companyName') {
      return {
        key: item?.originalName,
        title: title,
        dataIndex: ['title', 'category'].includes(item?.originalName)
          ? [item?.originalName, user.language ? user.language.selected.code : '']
          : item?.originalName,
        render: (text, record) => <Link to={singleCompanyPath + record._id}>{text}</Link>,
        ...getColumnSearchProps(item),
      };
    } else {
      return {
        key: item?.originalName,
        title: title,
        dataIndex: ['title', 'category'].includes(item?.originalName)
          ? [item?.originalName, user.language ? user.language.selected.code : '']
          : item?.originalName,
        ...getColumnSearchProps(item),
      };
    }
  });

  console.log('columns', columns);
  console.log('columnKeys', columnKeys);
  // const setSelectedCompany = (id) => {
  //   localStorage.setItem('selectedCompany', id);
  // };

  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
        {/*<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>*/}
        {/*  /!*<Link to='/admin/new-branch' onClick={() => setSelectedCompany(record._id)}>*!/*/}
        {/*  /!*  <PlusOutlined*!/*/}
        {/*  /!*    title={`Dodaj ogranak`}*!/*/}
        {/*  /!*    style={{ textDecoration: 'none', color: 'black' }} />*!/*/}
        {/*  /!*</Link>*!/*/}
        {/*</div>*/}
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={contactsPath + record._id}>
            <ContactsOutlined
              className='icon-unlock'
              title={`Vidi kontakt podatke`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <ContactsFilled
              className='icon-lock'
              title={`Vidi kontakt podatke`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={editPath + record._id}>
            <EditOutlined
              className='icon-unlock'
              title={`Izmeni podatke`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <EditFilled
              className='icon-lock'
              title={`Izmeni podatke`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>

        {/* {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                title={`Pregled korisnika`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeFilled
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                title={`Pregled korisnika`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )} */}

        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <DeleteOutlined
            className='icon-unlock'
            // title={`Delete ${title.toLowerCase()}`}
            title={`Brisanje klijenta`}
            onClick={() => {
              setDeletePop(!deletePop);
              setPromptName(record.companyName);
              setDeleteUser(record._id);
            }}
          />
          <DeleteFilled
            className='icon-lock'
            // title={`Delete ${title.toLowerCase()}`}
            title={`Brisanje klijenta`}
            onClick={() => {
              setDeletePop(!deletePop);
              setPromptName(record.companyName);
              setDeleteUser(record._id);
            }}
          />
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteHandler(deleteUser)}
            okText='Da'
            cancelText='Ne'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <p>Da li ste sigurni da želite da obrišete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    ),
  });

  // const expandedRowRender = (record) => {
  //   const columns = [
  //     { title: 'Adresa', dataIndex: 'address', key: 'address' },
  //     { title: 'Grad', dataIndex: 'city', key: 'city' },
  //     { title: 'Ogranak', dataIndex: 'branchName', key: 'branchName' },
  //     { title: 'Kreirano', dataIndex: 'createdAt', key: 'createdAt' },
  //     { title: 'Ažurirano', dataIndex: 'updatedAt', key: 'updatedAt' },
  //     {
  //       title: "Opcije",
  //       render: (clicked) => (
  //         <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
  //           <Link to={contactsPath + record._id}>
  //             <ContactsOutlined
  //               className='icon-unlock'
  //               title={`Vidi kontakt podatke`}
  //               style={{ textDecoration: 'none', color: 'black' }} />
  //             <ContactsFilled
  //               className='icon-lock'
  //               title={`Vidi kontakt podatke`}
  //               style={{ textDecoration: 'none', color: 'black' }} />
  //           </Link>
  //           <Link to={'/admin/edit-branch/' + clicked._id}>
  //             <EditOutlined title={`Izmeni ogranak`} style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} />
  //           </Link>
  //           <Popconfirm
  //             overlayClassName='desno-popconfirm'
  //             placement='left'
  //             title={`Da li želite da obrišete ogranak ${record.companyName}?`}
  //             onConfirm={() => deleteBranch(clicked._id)}
  //             okText='Da'
  //             cancelText='Ne'
  //           >
  //             <DeleteOutlined title={`Ukloni ogranak`} className='icon-unlock' style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} />
  //           </Popconfirm>
  //         </div >
  //
  //       )
  //     }
  //   ];
  //
  //   const data = [];
  //   for (let i = 0; i < branches.length; ++i) {
  //     data.push({
  //       key: i,
  //       _id: branches[i]._id,
  //       company: branches[i].company,
  //       address: branches[i].address,
  //       city: branches[i].city,
  //       branchName: branches[i].branchName,
  //       createdAt: new Date(branches[i].createdAt).toUTCString().substring(5, 22),
  //       updatedAt: new Date(branches[i].updatedAt).toUTCString().substring(5, 22),
  //     });
  //   }
  //
  //   return (
  //     <Table
  //       columns={columns}
  //       dataSource={data.filter(r => r.company === record._id)}
  //       pagination={false}
  //     />
  //   );
  // };

  // const getBranches = async (id) => {
  //   try {
  //     const call = await axios.post(`${SERVER_URL}/company/branches`, { companies: id }, {
  //       withCredentials: false,
  //       headers: { Authorization: `Bearer ${user.data.token}` }
  //     });
  //     setBranches(call.data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  for (let i = 0; i < columns.length; i++) {
    // columns[i].title = t(`${columns[i].title}`);
    columns[i].title = `${columns[i].title}`;
    // columns[i].title = columns[i].title;
  }

  return (
    <div>
      {data && (
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          // expandable={{ expandedRowRender }}
          // onExpandedRowsChange={rows => getBranches(rows)}
          pagination={{
            defaultPageSize: 20,
            position: ['topRight'],
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      )}
    </div>
  );
};

export default CompanyTable;
