import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
import { SERVER_URL } from '../../config';
// import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
// import i18n from '../../i18n';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav, setCollapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  const [count, setCount] = useState(undefined);

  const getObligationsCount = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/notifications`);

      setCount(response.data.numberOfObligations);
    } catch (error) {
      console.log('error with getting obligations number', error.message);
    }
  };

  useEffect(() => {
    getObligationsCount();
  }, [setCount]);

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/kojan_logo.png' style={{ maxHeight: '49px', width: '70%' }} alt='Kojan' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {currentuser.data &&
          ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.includes(item))).map(
            (route) => (
              <SubMenu
                className='submenu'
                key={route.label.toLowerCase()}
                title={
                  isCollapsed ? (
                    <route.icon />
                  ) : route.label === 'Obligacije' ? (
                    <span>
                      {route.label}
                      {/* &nbsp;&nbsp;&nbsp; */}
                      {/* <span
                        style={{
                          background: '#16648D',
                          borderRadius: '50%',
                          height: '50px',
                          width: '50px',
                          color: 'white',
                          position: 'relative',
                          padding: '3px',
                        }}
                      >
                        &nbsp;{count}&nbsp;
                      </span> */}
                    </span>
                  ) : (
                    <span>{route.label}</span>
                  )
                }
              >
                {route.children
                  .filter((item) => item.showInMenu)
                  .map((item) => (
                    <Menu.Item key={item.path}>
                      <Link to={item.path} onClick={() => setCollapsedNav(false)}>{item.label}</Link>
                    </Menu.Item>
                  ))}
              </SubMenu>
            ),
          )}
      </Menu>
      <div className='sider-profile-controls'>
        <a href={`/admin/view-user/${currentuser && currentuser?.data?.id}`} className='sider-profile-controls-profile'>
          <UserOutlined />
        </a>

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
      </div>
    </Sider>
  );
};
export default NavMenu;
