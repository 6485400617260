import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { Typography, Input, Modal, Select, Form, Card, notification, DatePicker, Row, Col, Upload, Button } from 'antd';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';
import { DownloadOutlined, UploadOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import NewProfacture from '../profactures/NewProfacture';

import Table from '../../components/tables/InvoiceTable';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const initialValues = {
  taskName: undefined,
  taskStatus: 'U PROCESU',
  taskText: undefined,
  obligation: [{ obligationText: undefined, obligationStatus: undefined, obligationDate: undefined }],
};

const SingleCompany = (props, data) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [filesDatabase, fetchFilesDatabase] = useAxios('', [], currentuser.data.token, 'get');
  const [fileList, setFileList] = useState([]);
  const [company, fetchCompany] = useAxios('', [], currentuser.data.token, 'get');
  const [tasks, fetchTasks] = useAxios('', [], currentuser.data.token, 'get');
  const { id } = props.match.params;
  const [isAdding, setIsAdding] = useState(false);
  const [isObligationVisible, setIsObligationVisible] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [isError, setIsError] = useState(false);
  const [isNewTask, setIsNewTask] = useState();
  const [currentTask, setCurrentTask] = useState();

  const [invoices, fetchInvoices] = useAxios('', [], currentuser.data.token, 'get');

  const [proInvoices, fetchProInvoices] = useAxios('', [], currentuser.data.token, 'get');

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (companyName) => {
    setModalOpen(true);
    // setSelectedCompanyName(companyName);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // setSelectedCompanyName(null);
  };

  const TABLE_COLUMN_KEYS = [
    '_id',
    '__v',
    'content',
    'orderId',
    'userEmail',
    'user',
    'userId',
    'buyerName',
    'totalPrice',
  ];

  const TABLE_COLUMN_KEYS_PRO_INVOICE = [
    '_id',
    '__v',
    'content',
    'orderId',
    'userEmail',
    'user',
    'userId',
    'buyerName',
    'totalPriceWithTax',
    'id',
    'dateOfDocument',
    'dateOfSupply',
    'orderNumber',
    'totalPrice',
    'guestUserData',
    'companyName',
    'order',
  ];

  let formData = currentTask !== undefined ? { ...tasks?.data?.tasks[currentTask] } : initialValues;

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined className='upload-button' />,
    showRemoveIcon: true,
    removeIcon: <DeleteOutlined />,
  };

  useEffect(() => {
    if (currentTask !== undefined) {
      formData = { ...tasks?.data?.tasks[currentTask] };
    }
  }, [currentTask]);

  const openPdf = async (path) => {
    try {
      const resPdF = await Axios.get(`${SERVER_URL}/singe-invoice/pdf?path=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const download = async (path) => {
    console.log('open pdf', path.path);
    try {
      const resPdF = await Axios.get(`${SERVER_URL}/download/file?path=${path.path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const removeFile = async (file) => {
    notification.info({
      message: 'Removing file in progress.',
      placement: 'bottomRight',
    });
    let newFileList = [...fileList];
    let newFiles = [...files];

    let foundFile;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].name === file.name) {
        foundFile = fileList[i];
        newFileList.splice(i, 1);

        if (company && company?.attachments?.length !== 0) {
          company?.attachments?.splice(i, 1);
          await Axios.put(
            `${SERVER_URL}/companies/${id}`,
            { attachments: company.attachments },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        }
      }
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].originalName === file.name) {
        newFiles.splice(i, 1);
      }
    }
    let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
    if (linkParts && linkParts.length !== 0) {
      await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {
        withCredentials: false,
      });
    }
    setFileList(newFileList);
    setFiles(newFiles);
  };
  useEffect(() => {
    console.log('file list', fileList);
  }, [fileList]);

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      fetchCompany(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } else {
      console.log('nepoznat ID');
    }
  }, [currentuser.data.token, currentuser.data.role, company, fetchCompany, id]);

  const buyer = company?.data?.companyName;

  console.log('BUYER', buyer);

  useEffect(() => {
    if (buyer) fetchInvoices(`${SERVER_URL}/invoices/${buyer}`, {});
  }, [fetchInvoices, buyer]);

  useEffect(() => {
    if (buyer) fetchProInvoices(`${SERVER_URL}/orders-by-buyer/${buyer}`, {});
  }, [fetchProInvoices, buyer]);

  console.log('INVOICES', invoices);

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      const filter = { company: id };
      fetchTasks(`${SERVER_URL}/tasks?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } else {
      console.log('nema taskova');
    }
  }, [currentuser.data.token, currentuser.data.role, fetchTasks, id]);

  useEffect(() => {
    if (currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('admin')) {
      const filter = { company: id };
      fetchFilesDatabase(`${SERVER_URL}/files?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } else {
      console.log('nema fajlova');
    }
  }, [currentuser.data.token, currentuser.data.role, company, fetchFilesDatabase, id]);

  const onFinish = async (values) => {
    try {
      isNewTask
        ? Axios.post(
            `${SERVER_URL}/tasks`,
            { ...values, company: id },
            { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
          )
        : Axios.put(
            `${SERVER_URL}/tasks/${tasks?.data?.tasks[currentTask]._id}`,
            { ...values, company: id },
            { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
          );
      setCurrentTaskId('');
      notification.success({
        message: isNewTask ? `Task je kreiran.` : `Uspešno ste izmenili task.`,
        placement: 'bottomRight',
      });
      setCurrentTask(undefined);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : `Problem sa kreiranjem taska. Molim vas da pokušate kasnije.`,
        placement: 'bottomRight',
      });
    }
  };

  const onFinishObligation = async (values) => {
    console.log('current task id', currentTaskId);
    console.log('values', values);
    try {
      const obligation = Axios.put(
        `${SERVER_URL}/tasks/${currentTaskId}`,
        { values: values, company: id },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      console.log('obligation', obligation);
      setCurrentTaskId('');
      notification.success({
        message: `Obligacija je dodata.`,
        placement: 'bottomRight',
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1200);
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : `Problem sa dodavanjem obligacije. Molim vas da pokušate kasnije.`,
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (invoices && invoices.data && invoices.data.length > 0) {
    const keys = Object.keys(invoices.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    // columnKeys.splice(1, 0, 'userEmail');
  }

  let tableData = [];
  if (invoices && invoices.data && invoices.data.length > 0) {
    tableData = invoices.data.map((item) => {
      // if (item.userId) {
      //   item.userEmail = item.userId.email;
      // }
      item.createdAt = new Date(item.createdAt).toUTCString().substring(5, 22);
      item.updatedAt = new Date(item.updatedAt).toUTCString().substring(5, 22);
      item.dateOfPayment = item.dateOfPayment.split('/').join('-');
      return item;
    });
  }

  // console.log('PROINVOICE', proInvoices);

  let columnKeysProInvoice;
  if (proInvoices && proInvoices.data && proInvoices.data.length > 0) {
    console.log('PROINVOICE', proInvoices);
    const keys = Object.keys(proInvoices.data[0]);
    console.log('keeeey', keys);
    columnKeysProInvoice = keys.filter((k) => !TABLE_COLUMN_KEYS_PRO_INVOICE.includes(k));
    // columnKeys.splice(1, 0, 'userEmail');
  }

  let tableDataProInvoice = [];
  if (proInvoices && proInvoices.data && proInvoices.data.length > 0) {
    tableDataProInvoice = proInvoices.data.map((item) => {
      // if (item.userId) {
      //   item.userEmail = item.userId.email;
      // }
      item.createdAt = new Date(item.createdAt).toUTCString().substring(5, 22);
      item.updatedAt = new Date(item.updatedAt).toUTCString().substring(5, 22);
      item.dateOfPayment = item.dateOfPayment.split('/').join('-');
      return item;
    });
  }

  const resetAdding = () => {
    setIsAdding(false);
  };

  const resetIsObligationVisible = () => {
    setIsObligationVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={null} // You can customize the footer if needed
        centered
        style={{ minWidth: '1000px' }}
        width={'1000px'}
      >
        <NewProfacture companyName={company?.data?._id} onClose={handleCloseModal} />
      </Modal>
      <div className='table'>
        <div className='contacts-header'>
          <h3>Kompanija {company?.data?.companyName}</h3>
        </div>
        <div className='actions-block'>
          <Link to='#'>
            <button
              className='b2b-primary'
              onClick={() => {
                setIsAdding(true);
                setIsNewTask(true);
              }}
            >
              Dodaj task
            </button>
          </Link>
        </div>

        <Row span={24} style={{ marginBlock: '50px', paddingInline: '15px' }} justify='space-between'>
          <Col span={11}>
            <h2>Dokumenta</h2>
            <Upload
              fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
              listType='text'
              showUploadList={showUploadList}
              action={`${SERVER_URL}/upload-file`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'static/pdf/',
                timestamp: Date.now().toString(),
                company: id,
              }}
              onChange={(info) => {
                console.log('info', info);
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);

                  setFiles([...files, { info }]);
                  for (const file of info.fileList) {
                    let nameArray = file.name.split('.');
                    switch (nameArray[nameArray.length - 1]) {
                      case 'pdf':
                        file.thumbUrl = '/pdf.png';
                        break;
                      case 'docx':
                        file.thumbUrl = '/docx.png';
                        break;
                      case 'png':
                        file.thumbUrl = '/png.png';
                        break;
                      case 'jpg':
                        file.thumbUrl = '/jpg.png';
                        break;
                      case 'xlsx':
                        file.thumbUrl = '/xlsx.png';
                        break;

                      default:
                        file.thumbUrl = '/other.png';
                        break;
                    }
                  }
                  notification.success({
                    message: 'Upload successfull',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Upload failed',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              onRemove={async (file) => {
                console.log('download', file);
                notification.info({
                  message: 'Brisanje dokumenta',
                  placement: 'bottomRight',
                });
                console.log('original', file);
                const urlParts = file?.name;
                console.log('url parts', urlParts);
                await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                  withCredentials: false,
                });
                window.location.reload();
              }}
              onDownload={async (file) => {
                // console.log('download', file);
                notification.info({
                  message: 'Downloading in progress.',
                  placement: 'bottomRight',
                });
                const urlParts = file.response.key.split('/');
                const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
                  withCredentials: false,
                });
                // eslint-disable-next-line no-undef
                FileSaver.saveAs(response.config.url, file.name);
              }}
            >
              <Button icon={<UploadOutlined />}>Dodaj dokument</Button>
            </Upload>
          </Col>
        </Row>
        <div className='single-company'>
          <div className='single-company__left-part'>
            {tasks?.data?.tasks?.length !== 0 ? (
              tasks?.data?.tasks?.map((task, index) => {
                const date = new Date(task.obligationDate);

                return (
                  <div className='task-kartica' key={index} style={{ position: 'relative' }}>
                    <EditOutlined
                      style={{ position: 'absolute', padding: '5px', top: '10px', right: '15px' }}
                      onClick={() => {
                        setCurrentTask(index);
                        setIsNewTask(false);
                        setIsAdding(true);
                      }}
                    />
                    <div className='task-obligacija'>
                      <div>
                        <h3>Task:</h3>

                        <p>Naziv taska: {task.taskName}</p>
                        <p>Opis taska: {task.taskText}</p>
                        <h4>Status taska: {task.taskStatus}</h4>
                      </div>
                      {task.obligationText ? (
                        <div style={{ paddingTop: '20px' }}>
                          <h3>Obligacija:</h3>
                          <p>Tekst obligacije: {task.obligationText}</p>
                          <p>Datum obligacije: {date.toLocaleDateString('en-GB')}</p>
                          <h4>Status obligacije: {task.obligationStatus}</h4>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {!task.obligationText ? (
                      <Link to='#'>
                        <button
                          className='b2b-primary'
                          onClick={() => {
                            setIsObligationVisible(true);
                            setCurrentTaskId(task._id);
                          }}
                        >
                          Dodaj obligaciju
                        </button>
                      </Link>
                    ) : null}
                  </div>
                );
              })
            ) : (
              <div className='taskovi-no-task'>
                <h2>NEMA TASKOVA</h2>
              </div>
            )}
            <h2 style={{ textAlign: 'left', marginTop: '40px' }}>Fakture</h2>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              {invoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!invoices.isLoading && invoices.data && invoices.data.length > 0 && (
                <Table
                  data={tableData}
                  // deleteHandler={deleteOrderHandler}
                  columnKeys={columnKeys}
                  title='Invoices'
                  openPdf={openPdf}
                />
              )}
              {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length === 0 && (
                <h2>NEMA FAKTURA</h2>
              )}
            </div>

            <h2 style={{ textAlign: 'left', marginTop: '40px' }}>Profakture</h2>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              {proInvoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!proInvoices.isLoading && proInvoices.data && proInvoices.data.length > 0 && (
                <Table
                  data={tableDataProInvoice}
                  // deleteHandler={deleteOrderHandler}
                  columnKeys={columnKeysProInvoice}
                  title='Invoices'
                  openPdf={openPdf}
                />
              )}
              {!proInvoices.isLoading &&
                proInvoices.data &&
                proInvoices.data.items &&
                proInvoices.data.items.length === 0 && <h2>NEMA FAKTURA</h2>}
            </div>
          </div>

          <div className='single-company__right-part'>
            <div className='actions-block'>
              <div className='b2b-primary'>
                <div onClick={() => handleOpenModal(company?.data?.companyName)}>
                  <button>Kreiraj profakturu</button>
                </div>
              </div>
            </div>
            <div className='panel-body'>
              <Card title='Podaci o kompaniji' bordered={false}>
                <p>
                  <Text strong>Naziv kompanije: </Text>
                  {company.data.companyName ? company.data.companyName : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>Matični broj: </Text>
                  {company.data.matBr ? company.data.matBr : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>PIB: </Text>
                  {company.data.taxId ? company.data.taxId : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>Adresa: </Text>
                  {company.data.address ? company.data.address : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>Grad: </Text>
                  {company.data.city ? company.data.city : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>Telefon: </Text>
                  {company.data.phone ? company.data.phone : 'Nema podataka'}
                </p>
                <p>
                  <Text strong>Poštanski kod: </Text>
                  {company.data.postalCode ? company.data.postalCode : 'Nema podataka'}
                </p>
              </Card>
              <br />
            </div>
            <div className='panel-body'>
              <Card title='Dokumenta:' bordered={false}>
                {fileList.map((file, index) => {
                  <div key={index}>
                    <p>{file.originalname}</p>
                    <div>
                      <DownloadOutlined style={{ paddingInline: '10px' }} onClick={() => download(file)} />
                      <DeleteOutlined style={{ paddingInline: '10px' }} onClick={() => removeFile(file)} />
                    </div>
                  </div>;
                })}
                {filesDatabase && filesDatabase.data ? (
                  filesDatabase?.data?.map((file, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p>{file.originalname}</p>
                      <div>
                        <DownloadOutlined style={{ paddingInline: '10px' }} onClick={() => download(file)} />
                        <DeleteOutlined
                          style={{ paddingInline: '10px' }}
                          onClick={async () => {
                            console.log('download', file);
                            notification.info({
                              message: 'Brisanje dokumenta',
                              placement: 'bottomRight',
                            });
                            console.log('original', file.originalname);
                            const urlParts = file?.originalname;
                            console.log('url parts', urlParts);
                            await Axios.post(`${SERVER_URL}/remove-file/${urlParts}`, {
                              withCredentials: false,
                            });
                            window.location.reload();
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nema podataka</p>
                )}
              </Card>
              <br />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={isNewTask ? 'Dodavanje taska' : 'Edit taska'}
        visible={isAdding}
        onCancel={() => {
          resetAdding();
          setCurrentTask(undefined);
        }}
        cancelText='Otkaži'
        centered='true'
        onOk={() => onFinish(form.getFieldsValue())}
        okText={isNewTask ? 'Dodaj' : 'Izmeni'}
        className='task-modal'
      >
        <Form
          className='form-horizontal'
          initialValues={formData}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <div className='task-wrapper' style={{ marginBottom: 0 }}>
            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Task'
              name='taskName'
            >
              <Input placeholder='Naziv taska' />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Status taska'
              name='taskStatus'
            >
              <Select>
                <Option value='U PROCESU'>U PROCESU</Option>
                <Option value='PRIHVAĆENO'>PRIHVAĆENO</Option>
                <Option value='ODBIJENO'>ODBIJENO</Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Opis taska'
              name='taskText'
            >
              <TextArea rows={4} placeholder='Tekstualni opis taska' />
            </Form.Item>
            {isNewTask && (
              <div>
                <Form.Item
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  label='Obligacija'
                  name='obligationText'
                >
                  <Input placeholder='Naziv obligacije' />
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  label='Status obligacije'
                  name='obligationStatus'
                >
                  <Select>
                    <Option value='OTVORENA'>OTVORENA</Option>
                    <Option value='ZATVORENA'>ZATVORENA</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  label='Datum obligacije'
                  name='obligationDate'
                >
                  <DatePicker />
                </Form.Item>
              </div>
            )}
            {/* <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Obligacija'
              name='obligationText'
            >
              <Input placeholder='Naziv obligacije' />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Status obligacije'
              name='obligationStatus'
            >
              <Select>
                <Option value='OTVORENA'>OTVORENA</Option>
                <Option value='ZATVORENA'>ZATVORENA</Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Datum obligacije'
              name='obligationDate'
            >
              <DatePicker />
            </Form.Item> */}
          </div>
        </Form>
        {/* Naziv taska:
        <Input
        // // value={addingStudent && addingStudent.name} 
        // // onChange={(e) => {setAddingStudent((pre) => { return { ...pre, name:e.target.value } })}} 
        // />
        Status taska:
        <Select>
          <Option value='U PROCESU'>U PROCESU</Option>
          <Option value='PRIHVAĆENO'>PRIHVAĆENO</Option>
          <Option value='ODBIJENO'>ODBIJENO</Option>
        </Select>
        Opis taska:
        <Input
        value={addingStudent && addingStudent.email} 
        onChange={(e) => {setAddingStudent((pre) => { return { ...pre, email:e.target.value } })}} 
        /> */}
      </Modal>
      <Modal
        title='Dodavanje obligacije'
        visible={isObligationVisible}
        onCancel={() => {
          resetIsObligationVisible();
          setCurrentTaskId('');
        }}
        cancelText='Otkaži'
        centered='true'
        onOk={() => onFinishObligation(form.getFieldsValue())}
        okText='Dodaj'
        className='task-modal'
      >
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinishObligation(values)}
          layout='vertical'
          form={form}
        >
          <div className='task-wrapper' style={{ marginBottom: 0 }}>
            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Obligacija'
              name='obligationText'
            >
              <Input placeholder='Naziv obligacije' />
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Status obligacije'
              name='obligationStatus'
            >
              <Select>
                <Option value='OTVORENA'>OTVORENA</Option>
                <Option value='ZATVORENA'>ZATVORENA</Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                display: 'inline-block',
                width: '100%',
              }}
              label='Datum obligacije'
              name='obligationDate'
            >
              <DatePicker />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SingleCompany;
