import React, { useEffect, useState } from 'react';
import { Tabs, Divider, Input, Select, Form } from 'antd';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

let formInit = {
  name: {},
  SEOTitle: {},
  SEODescription: {},
  SEOKeywords: {},
  image: undefined,
  loading: false,
  attributes: [],
};

const CategoryForm = ({ onSubmit, category, token, language, setEdit, SERVER_URL }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete category[key]);

  let initialValues = { ...formInit, ...category };

  useEffect(() => {
    fetchAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":false}`, []);
    if (language && language.selected) setTab(language.selected.code);
    form.resetFields();
  }, [fetchAttributes, language, SERVER_URL, category, form /*, categoryImage */]);

  useEffect(() => {
    if (category && category.image) {
      setImage(category.image.url);
    }
  }, [category, form]);

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'image' });
  };

  const deleteImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ image: null });
  };

  const onInsertIMage = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span style={{ padding: '4px', borderRadius: '4px' }}>{!category.name ? 'DODAJ' : 'IZMENI'}</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red', borderRadius: '4px' }}
            onClick={() => {
              setEdit({ visible: false, category: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>

      <div className='panel-body'>
        {attributes.isLoading && <h2 style={{ textAlign: 'center' }}>LOADING</h2>}
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !category.name)}
          layout='vertical'
          form={form}
        >
          {/* <h3 style={{ marginBottom: '8px' }}>{tab} language</h3> */}
          <Tabs
            onChange={(key) => setTab(key)}
            tabPosition='left'
            defaultActiveKey={language ? language.default.code : undefined}
          >
            {/* {language.list.map((lang) => ( */}
            <Tabs.TabPane key={language.default.code} forceRender>
              <Form.Item
                label='Naziv'
                rules={[
                  {
                    required: language.default.code === language.default.code ? true : false,
                    message: 'Molim Vas da unesete naziv kategorije!',
                  },
                ]}
                name={['name', language.default.code]}
              >
                <Input />
              </Form.Item>

              <Form.Item label='Opis naziva kategorije' name={['headerContent', language.default.code]}>
                <Input.TextArea rows={6}/>
              </Form.Item>

              <Form.Item label='SEO Naziv' name={['SEOTitle', language.default.code]}>
                <Input />
              </Form.Item>
              <Form.Item label='SEO Opis' name={['SEODescription', language.default.code]}>
                <Input />
              </Form.Item>
              <Form.Item label='SEO Ključne reči' name={['SEOKeywords', language.default.code]}>
                <Input />
              </Form.Item>
            </Tabs.TabPane>
            {/* ))} */}
          </Tabs>

          <Divider type='horizontal' />

          {/* <Form.Item label='Atributi' name='attributes'>
            <Select mode='multiple'>
              {!attributes.isLoading &&
                attributes.data &&
                attributes.data.items &&
                attributes.data.items.length > 0 &&
                attributes.data.items.map((attr) => (
                  <Select.Option value={attr._id} key={attr._id}>
                    {attr.name[language.selected.code]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item> */}

          <Form.Item label='Slika' name='image' valuePropName='image'>
            <UploadBox
              editHandler={imageHandler}
              deleteHandler={deleteImageHandler}
              image={image}
              index={0}
              name='image'
            />
          </Form.Item>

          <div className='text-right'>
            <button className='b2b-primary submit'>{!category.name ? 'Dodaj' : 'Izmeni'}</button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/categories/'
            imageType='category'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsertIMage(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryForm;
