import React from 'react';
import { Table, Input, Popconfirm } from 'antd';
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

const ImportsTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  removeProduct,
}) => {
  // const { t } = useTranslation();
  const history = useHistory();
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={'Pretraga za ' + `${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item,index) => {
    let title = ''
    if(index === 0) title = 'Naziv'
    if(index === 1) title = 'Ukupna cena bez takse (EURO)'
    if(index === 2) title = 'Ukupna cena bez takse (RSD)'
    if(index === 3) title = 'Datum uvoza'
    if(index === 4) title = 'Kreirao'
    if(index === 5) title = 'Kreirano'
    if(index === 6) title = 'Izmenjeno'
    
    return {
      key: item,
      title: title,
      dataIndex:
        item === '_id'
          ? 'user'
          : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item]
            : [item]
          : item,
      ...getColumnSearchProps(
        item === '_id'
          ? 'user'
          : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item]
            : [item]
          : item,
      ),
    };
  });

  if (history.location.pathname.includes('/admin/edit-profacture/')) {
    columns.splice(1, 0, {
      title: 'category',
      render: (record) => <p>{record.category}</p>,
    });
    columns.push({
      title: 'Opcije',
      render: (record) => (
        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            placement='left'
            title={`Da li želite da uklonite uvoz?`}
            onConfirm={() => removeProduct(record._id)}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <DeleteOutlined title={`Obriši uvoz`} />
          </Popconfirm>
        </div>
      ),
    });
  } else {
    columns.push({
      title: 'Opcije',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={editPath + record._id}>
              <EditOutlined
                className='icon-unlock'
                title={`Izmena uvoza`}
                style={{ textDecoration: 'none', color: 'black' }}
                dataId={record._id}
              />
              <EditFilled
                className='icon-lock'
                title={`Izmena uvoza`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>

          {viewPath && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
              <Link to={viewPath + record._id}>
                <EyeOutlined
                  className='icon-unlock'
                  title={`Pregled ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
                <EyeOutlined
                  className='icon-lock'
                  title={`Pregled ${title.toLowerCase()}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                />
              </Link>
            </div>
          )}

          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <Popconfirm
              placement='left'
              // title={`This will delete ${title.toLowerCase()}`}
              title={`Ovo će izbrisati uvoz`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined
                className='icon-unlock'
                // title={`Delete ${title.toLowerCase()}`}
                title={`Brisanje uvoza`}
              />
              <DeleteFilled
                className='icon-lock'
                // title={`Delete ${title.toLowerCase()}`}
                title={`Brisanje uvoza`}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    });
  }

  for (let i = 0; i < columns.length; i++) {
    // columns[i].title = t(`${columns[i].title}`);
    columns[i].title = `${columns[i].title}`;
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topRight'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default ImportsTable;
