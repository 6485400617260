import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Card, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

const ViewCompany = (props) => {
  const [form] = Form.useForm();
  const { companyId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (companyId) {
      fetchCompanies(`${SERVER_URL}/companies/${companyId}`, []);
    }
  }, [companyId, fetchCompanies]);

  const calcMethods = {
    automatic: 'Per month (regular work)',
    manual: ['Per month (all days of work)', 'Per month (all days)'],
    fixed: 'Per month (fixed defined value)',
    perDay: ['Per day (regular work)', 'Per day (holiday leave)'],
    monthlyFee: 'Per month (excluding sick leave)'
  };

  if (companies.data) {
    form.setFieldsValue({
      name: companies.data.name,
      code: companies.data.code,
      pib: companies.data.pib,
      zip: companies.data.zip,
      city: companies.data.city,
      address: companies.data.address,
      client: companies.data.client && companies.data.client.firstName + ' ' + companies.data.client.lastName,
      calculationMethodMeal: Array.isArray(calcMethods[companies.data.calculationMethodMeal]) ? calcMethods[companies.data.calculationMethodMeal][0] :
        calcMethods[companies.data.calculationMethodMeal],
      calculationMethodHoliday: Array.isArray(calcMethods[companies.data.calculationMethodHoliday]) ?
        companies.data.calculationMethodHoliday === 'perDay' ? calcMethods[companies.data.calculationMethodHoliday][1] :
          calcMethods[companies.data.calculationMethodHoliday][0] : calcMethods[companies.data.calculationMethodHoliday],
      travelExpenses: Array.isArray(calcMethods[companies.data.travelExpenses]) ? calcMethods[companies.data.travelExpenses][1] :
        calcMethods[companies.data.travelExpenses]
    });
  }

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <Button type='primary'>all companies</Button>
        </Link>
      </div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='VIEW COMPANY' bordered={false}>
            <Form className='form-horizontal companyForm' layout='horizontal' form={form}>
              <Form.Item label='Registration number' name='code'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Name of company' name='name' style={{ marginLeft: '15px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='TIN' name='pib' style={{ marginLeft: '58px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Postal number' name='zip' style={{ marginLeft: '40px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='City' name='city' style={{ marginLeft: '58px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Address' name='address' style={{ marginLeft: '58px' }}>
                <Input disabled />
              </Form.Item>

              {currentuser.data.role.includes('admin') && (
                <Form.Item label='Client' name='client' style={{ marginLeft: '58px' }}>
                  <Input disabled />
                </Form.Item>
              )}

              <Form.Item
                // className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for calculation of meal allowance'
                name='calculationMethodMeal'
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                //  className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for calculation of holliday allowance'
                name='calculationMethodHoliday'
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                //  className='calcMethod'
                labelCol={{ span: 24 }}
                label='Method for travel expenses'
                name='travelExpenses'
              >
                <Input disabled />
              </Form.Item>

              <div style={{ marginTop: '10px' }} className='text-center'>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => (window.location.href = '/admin/companies')}
                  type='primary'
                  htmlType='button'
                >
                  Close
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewCompany;
