import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Input, Checkbox, Form, Row, Col, TreeSelect } from 'antd';

const ViewData = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const { dataId } = props.match.params;
  const [form] = Form.useForm();
  let initialValues;

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (dataId) fetchData(`${SERVER_URL}/data/${dataId}`, {});
  }, [fetchCategories, fetchData, dataId]);

  let treeData;

  const isDataFetched = !data.isLoading && data && data.data && !categories.isLoading && categories.data && currentuser.language;
  if (isDataFetched) {
    initialValues = data.data[0];
    if (data?.data[0]?.cena[0]?.Cena) {
      initialValues.nekoristise = false;
    }
    if (data?.data[0]?.stanje[0]?.stanje > 0) {
      initialValues.inStock = true;
    }
    if (categories.data && categories.data.length > 0) {
      treeData = getTreeFromFlatData({
        flatData: categories.data.map((node) => ({
          ...node,
          title: node.naziv.sr,
          value: node.ID,
          // key: node.ID,
          parent: node.IDnadredjene ? node.IDnadredjene : null,
          _id: node.ID,
        })),
        getKey: (node) => node.ID, // resolve a node's key
        getParentKey: (node) => node.parent, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      });
    }
  }

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data'>
          <button className='b2b-primary'>Svi proizvodi</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {dataId && !data.isError && data.data && isDataFetched && (
          <div className='panel panel-body'>
            <div className='panel-body'>
              <Form
                className='form-horizontal'
                initialValues={initialValues}
                layout='vertical'
                form={form}
              >
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <div className='panel-body'>
                      <Form.Item
                        label='Naziv'
                        name='naziv'
                      >
                        <Input
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label='Šifra'
                        name='sifra'>
                        <Input disabled />
                      </Form.Item>
                      <Row justify='start'>
                        <Form.Item
                          label="Cena proizvoda"
                          name={['cena', '0', 'Cena']}>
                          <Input
                            disabled />
                        </Form.Item>
                      </Row>
                      <Row justify='start'>
                        <Form.Item
                          label="Stanje"
                          name={['stanje', '0', 'stanje']}>
                          <Input
                            disabled />
                        </Form.Item>
                      </Row>
                      <Row gutter={14}>
                        <Col xs={24} md={8}>
                          <Form.Item label='Neaktivan proizvod' name='nekoristise' valuePropName='checked'>
                            <Checkbox disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                          <Form.Item label='Na zalihama' name='inStock' valuePropName='checked'>
                            <Checkbox disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className='panel panel-primary'>
                      <div className='panel-heading'>
                        <h4 className='panel-title'>Kategorija</h4>
                      </div>
                      <br />
                      <Form.Item
                        label='Kategorija'
                        name='IDgrupe'
                        className='panel-body'
                        rules={[{ required: true, message: 'Molim Vas da odaberete kategoriju!' }]}
                      >
                        <TreeSelect
                          disabled
                          getPopupContainer={trigger => trigger.parentNode}
                          dropdownAlign={{ offset: [0, -80] }}
                          style={{ width: '100%', height: 30 }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          name='category'
                          treeData={treeData}
                          placeholder='Kategorija'
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div >

        )}
      </div>
    </div>
  );
};

export default ViewData;
