import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ObligationForm from '../../components/forms/ObligationForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';

const EditObligations = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [obligation, fetchObligation] = useAxios('', {}, currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;
  useEffect(() => {
    if (id) fetchObligation(`${SERVER_URL}/obligations/${id}`, {});
  }, [fetchObligation, id]);

  useEffect(() => {
    fetchClients(`${SERVER_URL}/companies`, {})
  }, [fetchClients, SERVER_URL]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/obligations` : `${SERVER_URL}/obligations/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Obligacija je ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/obligation');
    } catch (error) {
      console.log('error::', error);
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const isDataFetched = !obligation.isLoading && obligation.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/obligation'>
          <Button type='primary'>Sve obligacije</Button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && <ObligationForm isNew={true} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} items={clients} />}
        {id && !obligation.isError && Object.keys(obligation.data).length !== 0 && isDataFetched && (
          <ObligationForm isNew={false} data={obligation.data} language={currentuser.language} onSubmit={onSubmit} SERVER_URL={SERVER_URL} token={currentuser.data.token} items={clients} />
        )}
      </div>
    </div>
  );
};

export default EditObligations;
