import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { notification } from 'antd';
import CompanyForm from '../../components/forms/CompanyForm';

const EditCompany = (props) => {
  const [result, setResult] = useState(false);
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/companies/${id}`, {});
  }, [fetchData, id]);


  const createCompany = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/companies`, { ...data }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Klijent je kreiran.',
        placement: 'bottomRight',
      });
      setResult(true);
      console.log('result', result);
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem with creating company. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  const updateCompany = async (data) => {
    try {
      await Axios.put(`${SERVER_URL}/companies/${id}`, { ...data }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Klijent je izmenjen.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte ponovo kasnije',
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/companies'>
          <button className='b2b-primary'>Svi klijenti</button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && <CompanyForm isNew={true} language={currentuser.language} updateCompany={updateCompany}
                                              SERVER_URL={SERVER_URL} token={currentuser.data.token}
                                              createCompany={createCompany} />}
        {id && !data.isError && data.data && isDataFetched && (
          <CompanyForm isNew={false} data={data.data} language={currentuser.language} updateCompany={updateCompany}
                       SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
