import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Form, DatePicker, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ImportsTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const { RangePicker } = DatePicker;

const TABLE_COLUMN_KEYS = ['_id', '__v', 'products', 'totalQuantity'];

const Imports = () => {
  const currentuser = useContext(UserContext);
  const [items, fetchItems] = useAxios('', [], currentuser.data.token, 'get');

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedImportDate, setSelectedImportDate] = useState();

  useEffect(() => {
    fetchItems(`${SERVER_URL}/imports`, []);
  }, [fetchItems]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/import/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Uvoz je obrisan.',
        placement: 'bottomRight',
      });
      // history.push('/admin');
      // history.push('/admin/imports');
      window.location.reload();
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (items.data && items.data.items && items.data.items.length > 0) {
    const keys = Object.keys(items.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (items.data && items.data.items && items.data.items.length > 0) {
    tableData = items.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.importDate = moment(item.importDate).format('MM/DD/YYYY');
      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/import-data`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  tableData = tableData.filter((item) => {
    // Check if any product in the products array matches a selected value
    return selectedValues.length === 0 || item.products.some((product) => selectedValues.includes(product.productId));
  });

  if (selectedImportDate && selectedImportDate.length > 0) {
    tableData = tableData?.filter(
      (item) =>
        Date.parse(moment(item.importDate).format('YYYY-MM-DD')) >=
          Date.parse(moment(selectedImportDate[0]).format('YYYY-MM-DD')) &&
        Date.parse(moment(item.importDate).format('YYYY-MM-DD')) <=
          Date.parse(moment(selectedImportDate[1]).format('YYYY-MM-DD')),
    );
  }

  let products = [];

  if (items && items.data && items.data.products) {
    const uniqueProducts = items.data.products.filter(
      (item, index, self) => self.findIndex((p) => p.productId === item.productId) === index,
    );

    products = uniqueProducts;
  }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/new-import'>
          <button className='b2b-primary'>Dodaj ulaz</button>
        </Link>
        {/*<Button className='importbutton' disabled={!currentuser.data.role.includes('superAdmin')} style={{ marginLeft: '10px' }} icon={<UploadOutlined />} onClick={() => document.getElementById('input-file').click()} type="button">Uvoz podataka</Button>*/}
        {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
      </div>

      <div style={{ display: 'flex', gap: '40px', marginLeft: '5px' }}>
        <Form.Item label='Datum uvoza' name='importDate'>
          <RangePicker
            placeholder='Odaberite datum'
            format='DD/MM/YYYY'
            value={selectedImportDate}
            onChange={(val) => setSelectedImportDate(val)}
          />
        </Form.Item>
      </div>
      <div style={{ display: 'flex', gap: '40px', marginLeft: '10px' }}>
        <Form.Item label='Naziv proizvoda' name='title'>
          <Select
            showSearch
            placeholder='Odaberite proizvod'
            optionFilterProp='children'
            className='custom-select'
            mode='multiple'
            style={{ width: '200px' }} // Customize the width of the Select component
            dropdownStyle={{ width: '300px' }}
            onChange={(values) => {
              setSelectedValues(values); // Update the selected values in the state
            }}
          >
            {products?.map((val) => (
              <Select.Option value={val.productId} key={val._id}>
                {val.productId}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div style={{ textAlign: 'center' }}>
        {items.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!items.isLoading && items.data && items.data.items && items.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Imports'
            editPath='/admin/edit-import/'
            viewPath='/admin/view-import/'
          />
        )}
        {!items.isLoading && items.data && items.data.items && items.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Imports;
