import React from 'react';

const Home = () => {
  return (
    <div style={{
      textAlign: 'center',
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '200px',
      overflowX: 'auto'
    }}>
      <img src='/images/KoJan-logo-1.png' alt='KOJAN' />
    </div>
  );
};

export default Home;
