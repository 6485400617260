import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { DataForm } from '../../components/forms';

const EditData = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [declarations, fetchDeclarations] = useAxios('', [], currentuser.data.token, 'get');
  const { dataId } = props.match.params;

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (dataId) fetchData(`${SERVER_URL}/data/${dataId}`, {});
    fetchDeclarations(`${SERVER_URL}/declarations`);
  }, [fetchCategories, fetchData, fetchDeclarations, dataId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/data` : `${SERVER_URL}/data/${dataId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Proizvod je ${isNew ? 'kreiran.' : 'izmenjen.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/data');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && !categories.isLoading && categories.data && currentuser.language && !declarations.isLoading && declarations.data;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/data'>
          <button className='b2b-primary'>Svi proizvodi</button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!dataId && isDataFetched && (
          <DataForm
            isNew={true}
            categories={categories.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            declarations={declarations.data}
          />
        )}

        {dataId && !data.isError && data.data && isDataFetched && (
          <DataForm
            isNew={false}
            data={data.data}
            categories={categories.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            declarations={declarations.data}
          />
        )}
      </div>
    </div>
  );
};

export default EditData;
